import React from 'react';
import { Link } from 'react-router-dom';
import ShowText from './ShowText';

interface Blog {
  id: string;
  title: string;
  description: string;
}

const blogs: Blog[] = [
  { id: '1', title: 'Sueños y Proyectos', description: 'Aprende cómo mejorar tu mentalidad, gestionar tus emociones y maximizar tu potencial.' },
  { id: '2', title: 'Sobre mi rutina', description: 'Descubre herramientas prácticas para superar tus límites, construir hábitos positivos y alcanzar tus metas.' },
  { id: '3', title: 'El inicio del viaje', description: 'Explora cómo encontrar claridad en tus objetivos, mantenerte motivado y vivir con intención. Este espacio está dedicado a ayudarte a convertirte en la persona que siempre quisiste ser.' },
];

const BlogPage: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
          <div className="content">
            <ShowText color="black" size="3" family="Raleway" text="Blog" />
            <ShowText color="black" size="1.5" family="Montserrat" text="Aquí te dejo unos blogs que podrían ser de interés:"/>
            <br/>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {blogs.map((blog) => (
                <div
                  key={blog.id}
                  className="p-4 border border-gray-300 rounded-lg shadow hover:shadow-md transition-shadow"
                >
                  <ShowText color="black" size="2" family="Raleway" text={blog.title} />
                  <ShowText color="black" size="1" family="Montserrat" text={blog.description}/>
                  <br/>
                  <Link
                    to={`/blogs/${blog.id}`}
                    className="text-blue-500 hover:underline"
                  >
                    Leer más →
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
    </div>
  );
};

export default BlogPage;
