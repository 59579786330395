import React from 'react';

import ShowText from './../ShowText';
import ProfileCard from './../ProfileCard';

import heartImg from './../../assets/heart.png'
import bookImg from './../../assets/book.png';
import journalImg from './../../assets/journal.png';

const SelfDevelopment: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
            <div className="content">
                <ShowText color="black" size="2" family="Raleway" text="Self-Development &" />
                <ShowText color="black" size="2" family="Raleway" text="Life Improvement" />
                <br/>
            </div>
        </div>
        <div className="flex flex-wrap gap-20 p-6">
            <ProfileCard 
            imageSrc={bookImg}
            title="Atomic Habits - James Clear"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/40UFyqD"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="Think and Grow Rich - Napoleon Hill"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3YVWZ8J"
            />
            <ProfileCard 
            imageSrc={journalImg}
            title="The Five Minute Journal"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/4hHzSWT"
            />
            <ProfileCard 
            imageSrc={heartImg}
            title="Vision Board Kit"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3NXbORZ"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="The Power of Now - Eckhart Tolle"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/4fceR4R"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="How to Win Friends & Influence People - Dale Carnegie"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3Z0mIgk"
            />
        </div>
    </div>
  );
};

export default SelfDevelopment;