import React from 'react';

import ShowText from './../ShowText';
import ProfileCard from './../ProfileCard';

import fitnessGear from './../../assets/fitness_gear.png';
import fitnessClothes from './../../assets/fitness_clothes.png';
import bookImg from './../../assets/book.png';


const Fitness: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
            <div className="content">
                <ShowText color="black" size="2" family="Raleway" text="Fitness & Health" />
                <br/>
            </div>
        </div>
        <div className="flex flex-wrap gap-20 p-6">
            <ProfileCard 
            imageSrc={fitnessGear}
            title="At Home Gym Setup"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3UDVd9C"
            />
            <ProfileCard 
            imageSrc={fitnessClothes}
            title="EcoSmart Fleece Sweatshirt Hoodie"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3Z0plP6"
            />
            <ProfileCard 
            imageSrc={fitnessGear}
            title="Fitness Tracker"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/48ItMRO"
            />
            <ProfileCard 
            imageSrc={fitnessClothes}
            title="Buttery Soft Yoga Pants"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3YAIYfe"
            />
            <ProfileCard 
            imageSrc={fitnessGear}
            title="Thick Exercise Yoga Mat"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/4hARX93"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="The Juiceman's Power of Juicing - Jay Kordich"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3O0ar54"
            />
        </div>
    </div>
  );
};

export default Fitness;