import React from 'react';

import SelfDevelopment from './products/SelfDevelopment';
import Finance from './products/Finance';
import Fitness from './products/Fitness';
import TimeManagement from './products/TimeManagement';

const Products: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
          <SelfDevelopment />
        </div>
        <br/>
        <hr className="w-3/4 mx-auto border-t-2 border-gray-500 my-4" />
        <div className="foreground-container">
          <Finance />
        </div>
        <br/>
        <hr className="w-3/4 mx-auto border-t-2 border-gray-500 my-4" />
        <div className="foreground-container">
          <TimeManagement />
        </div>
        <br/>
        <hr className="w-3/4 mx-auto border-t-2 border-gray-500 my-4" />
        <div className="foreground-container">
          <Fitness />
        </div>
    </div>
  );
};

export default Products;