import React from 'react';

import Products from './Products';
import ShowText from './ShowText';
import Resource from './Resource';

import landing from './../assets/landing.png';

const Home: React.FC = () => {
  return (
    <div id="about" className='content-container'>
        <div className="foreground-container">
             <div className="content">
              <ShowText color="black" size="3.5" family="Raleway" text="Design your Rich Life" />
              <br/>
              <ShowText color="black" size="2" family="Montserrat" text="Whether you want to improve your time management, 
              personal finances, business or health habits, here I want to share with you content and products
              that can help you in your journey of designing your rich life." />
            </div>
            <div className="content">
              <img src={landing} alt="LandingPageImage" />
            </div>
        </div>
        <br/><br/>
        <Resource />
        <br/><br/>
        <Products />
    </div>
  );
};

export default Home;