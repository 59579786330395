import React from 'react';

interface ProfileCardProps {
  imageSrc: string;
  title: string;
  description: string;
  buyLink: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ imageSrc, title, description, buyLink }) => {
  return (
    <div className="flex flex-col items-center p-4 rounded-lg max-w-sm mx-auto shadow-lg bg-white">
      <img src={imageSrc} alt={title} className="rounded-full w-32 h-32 object-cover mb-4" />
      <h2 className="text-xl text-center font-semibold mb-2">{title}</h2>
      <p className="text-gray-600 text-center mb-4">{description}</p>
      <a
        href={buyLink}
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4 inline-block bg-blue-500 text-white font-semibold py-2 px-6 rounded-lg hover:bg-blue-600 transition-colors"
      >
        Try Amazon
      </a>
    </div>
  );
};

export default ProfileCard;