import React from 'react';

import ShowText from './ShowText';

import aboutImg from './../assets/about.png';

const About: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
          <div className="content">
            <img src={aboutImg} alt="aboutImg" />
          </div>
          <div className="content">
            <ShowText color="black" size="3" family="Raleway" text="About Me" />
            <br/>
            <ShowText color="black" size="1.5" family="Montserrat" text="
            Hola soy Joaquín!
            Se que el resto de la página web está en inglés, pero pensé que mínimo mi presentación debiese estar en mi idioma nativo. 
            Soy de Chile, Ingeniero Informático y un apasionado por el crecimiento y desarrollo personal.
            Bienvenido a este espacio en el que puedes dar tu primer paso para diseñar una vida en abundancia y alcanzar la mejor versión de ti mismo. 
            Te quiero presentar productos cuidadosamente seleccionados que tienen el potencial de ayudarte a transformar tu vida. 
            No se trata solo de los productos, aquí no hay nada que yo no le recomendaría a un amigo,
            espero genuinamente brindarte herramientas que impacten de manera positiva en tu día a día, en tus hábitos, en tus finanzas y 
            en tu bienestar.
            "/>
          </div>
        </div>
    </div>
  );
};

export default About;