import React from 'react';

interface ShowTextProps {
  color: string;
  size: string;
  family: string;
  text: string;
}

const ShowText: React.FC<ShowTextProps> = ({ color, size, family, text }) => {
  return (
      <h1 
      style={{
        fontSize: `${size}rem`,
        fontFamily: `'${family}', sans-serif`,
        color: `${color}`
      }}
      >
        {text}
      </h1>
  );
};

export default ShowText;