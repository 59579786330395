import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';

import Home from "./components/Home";
import About from "./components/About";
import BlogPage from "./components/BlogPage";
import BlogPostPage from "./components/BlogPostPage";

import brand from './assets/brand.png';
import logo from './assets/logo.png';
import './App.css';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-white-100">
        <div className="centered-content-container">
          <img src={brand} alt="Brand" className="brand" />
        </div>
        {/* Navbar */}
        <nav className="navbar">
          {/* Logo */}
          <div className="text-lg font-bold">
            <img src={logo} alt="Business Logo" className="logo-image" />
          </div>

          {/* Menu Toggle for Small Screens */}
          <div className="md:hidden flex justify-end">
            <button
              className="text-gray-300 hover:text-white focus:outline-none"
              onClick={() => {
                const menu = document.getElementById('mobile-menu');
                menu?.classList.toggle('hidden');
              }}
            >
              {/* Hamburger Icon */}
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns={logo}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

          {/* Navbar Links */}
          <div
            id="mobile-menu"
            className="hidden md:flex flex-col md:flex-row md:gap-6 md:items-center"
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                `${
                  isActive
                    ? 'text-black-400 after:absolute after:left-0 after:bottom-0 after:h-1 after:bg-black after:rounded-full after:w-full'
                    : 'hover:text-black-400'
                } text-3xl font-montserrat relative pb-2`
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `${
                  isActive
                    ? 'text-black-400 after:absolute after:left-0 after:bottom-0 after:h-1 after:bg-black after:rounded-full after:w-full'
                    : 'hover:text-black-400'
                } text-3xl font-montserrat relative pb-2`
              }
            >
              About
            </NavLink>
            <NavLink
              to="/blogs"
              className={({ isActive }) =>
                `${
                  isActive
                    ? 'text-black-400 after:absolute after:left-0 after:bottom-0 after:h-1 after:bg-black after:rounded-full after:w-full'
                    : 'hover:text-black-400'
                } text-3xl font-montserrat relative pb-2`
              }
            >
              Blog
            </NavLink>
          </div>
        </nav>

        {/* Main Content */}
        <div className="p-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/blogs/:id" element={<BlogPostPage />} />
            <Route path="*" element={<div>404 - Page Not Found</div>} />
          </Routes>
        </div>
        <br/><br/><br/><br/>
        <div className="centered-content-container">
          <img src={brand} alt="Brand" className="max-w-sm" />
        </div>
        <br/><br/>
      </div>
    </Router>
  );
}

export default App;
