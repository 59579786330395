import React from 'react';

import ShowText from '../ShowText';
import ProfileCard from '../ProfileCard';

import journalImg from './../../assets/journal.png';
import bookImg from './../../assets/book.png';


const Finance: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
            <div className="content">
                <ShowText color="black" size="2" family="Raleway" text="Finance & Business" />
                <br/>
            </div>
        </div>
        <div className="flex flex-wrap gap-20 p-6">
            <ProfileCard 
            imageSrc={bookImg}
            title="The Intelligent Investor - Benjamin Graham"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3ZuGqRv"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="The Little Book of Common Sense Investing - John C. Bogle"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/49nOmY3"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="The Richest Man in Babylon - George S. Clason"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3Oz4Bbb"
            />
            <ProfileCard 
            imageSrc={journalImg}
            title="Monthly Bill Payment Checklist"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/49ilhx7"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="The Psychology of Money - Morgan Housel"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3VjVaQB"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="Zig Ziglar's Secrets of Closing the Sale - Zig Ziglar"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3ZgVqkr"
            />
        </div>
    </div>
  );
};

export default Finance;