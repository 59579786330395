import React from 'react';

import ShowText from './../ShowText';
import ProfileCard from './../ProfileCard';

import journalImg from './../../assets/journal.png';
import bookImg from './../../assets/book.png';

const TimeManagement: React.FC = () => {
  return (
    <div className='content-container'>
        <div className="foreground-container">
            <div className="content">
                <ShowText color="black" size="2" family="Raleway" text="Time Management &" />
                <ShowText color="black" size="2" family="Raleway" text="Productivity" />
                <br/>
            </div>
        </div>
        <div className="flex flex-wrap gap-20 p-6">
            <ProfileCard 
            imageSrc={journalImg}
            title="Pro Daily Planner"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3NZp0WD"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="The 7 Habits of Highly Effective People - Stephen R. Covey"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/48HwYwY"
            />
            <ProfileCard 
            imageSrc={journalImg}
            title="2025 Ramsey Goal Planner"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/40GhuYd"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="Do More Better - Tim Challies"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/3CntW52"
            />
            <ProfileCard 
            imageSrc={journalImg}
            title="Katie Daisy Weekly Planner Calendar"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/4fmE4JO"
            />
            <ProfileCard 
            imageSrc={bookImg}
            title="Deep Work- Cal Newport"
            description="Get the best products and save money with Amazon"
            buyLink="https://amzn.to/40ZB4z1"
            />
        </div>
    </div>
  );
};

export default TimeManagement;