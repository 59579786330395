import React from 'react';

interface ColorChipProps {
  color: string;
  text: string;
}

const ColorChip: React.FC<ColorChipProps> = ({ color, text }) => {
  return (
    <div
      className={`w-40 h-12 rounded-full flex items-center justify-center ${color} hover:bg-black transition-colors duration-300`}
    >
      <span className="text-white">{text}</span>
    </div>
  );
};

export default ColorChip;
