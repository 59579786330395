import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShowText from './ShowText';

interface BlogPost {
  title: string;
  content: string;
}

type BlogContent = Record<string, BlogPost>;


const BlogPostPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [blog, setBlog] = useState<BlogPost | null>(null);
  
    useEffect(() => {
        import('./../data/blogContent.json')
          .then((data) => {
            const content = data.default as BlogContent; // Access the `default` property
            if (id && content.hasOwnProperty(id)) {
              setBlog(content[id]);
            } else {
              setBlog(null);
            }
          })
          .catch((err) => console.error('Failed to load blog content:', err));
      }, [id]);
  
    if (!blog) {
      return <ShowText color="black" size="1.5" family="Montserrat" text="Blog no encontrado"/>;
    }

  
    return (
        <div className='content-container'>
            <div className="foreground-container">
            <div className="content">
                <ShowText color="black" size="3" family="Raleway" text={blog.title} />
                <br/>
                <ShowText color="black" size="1.5" family="Montserrat" text={blog.content} />
            </div>
            </div>
        </div>
    );
  };
  
  export default BlogPostPage;