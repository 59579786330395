import React from 'react';

import ShowText from './ShowText';
import ColorChip from './ColorChip';

const Resource: React.FC = () => {
  return (
    <div className='content-container'>
      <ShowText color="black" size="3" family="Raleway" text="Content and Productos for" />
      <div className="flex flex-wrap gap-1 p-6">
          <ColorChip color="bg-blue-500" text="Life Improvement" />
          <ColorChip color="bg-green-500" text="Self Development" />
          <ColorChip color="bg-purple-500" text="Time Management" />
          <ColorChip color="bg-yellow-500" text="Finance" />
          <ColorChip color="bg-pink-500" text="Health" />
          <ColorChip color="bg-purple-500" text="Fitness" />
        </div>
        <div className="flex flex-wrap gap-1">
          <ColorChip color="bg-yellow-500" text="Wealth" />
          <ColorChip color="bg-blue-500" text="Business" />
          <ColorChip color="bg-pink-500" text="Self Help" />
          <ColorChip color="bg-green-500" text="Growth" />
          <ColorChip color="bg-gray-500" text="+ more" />
        </div>
    </div>
  );
};

export default Resource;